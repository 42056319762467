import { Directive, HostListener, ElementRef, Renderer2 } from "@angular/core";

@Directive({
  selector: "[appOpenPopOver]"
})
export class OpenPopoverDirective {
  popoverID: string = "";
  constructor(private elRef: ElementRef, private renderer: Renderer2) { }
  @HostListener('click') onClick() {
    if (document.body.offsetWidth > 992) {
      if (typeof this.renderer.nextSibling(this.elRef.nativeElement).id != 'undefined') {
        document.getElementById(this.renderer.nextSibling(this.elRef.nativeElement).id).classList.toggle("open");
        this.popoverID = this.renderer.nextSibling(this.elRef.nativeElement).id;
        // console.log(this.popoverID)
      } else {
        document.getElementsByClassName("popoverGlobal")[0].classList.remove("open")
      }
    }
  }

  @HostListener('document:click', ['$event']) clickout(event) {
    // event.stopImmediatePropagation()
    if (this.popoverID.includes("popOver")) {
      if (document.getElementById(this.popoverID).classList.contains("open")) {
        if (!(event.target.className.indexOf("popover") > -1 || event.target.className.indexOf(this.elRef.nativeElement.getAttribute('class')) > -1)) {
          document.getElementById(this.popoverID).classList.remove("open")
          //Remove active top link
          document.getElementById("top__navigation__link--" + 1).classList.remove("active");
        }
      }
    }

  }

  @HostListener('window:resize', ['$event']) onResize(event) {
    if (document.body.offsetWidth <= 991 && document.getElementsByClassName("popoverGlobal")[0].classList.contains("open")) {
      document.getElementsByClassName("popoverGlobal")[0].classList.remove("open")
      //Remove active top link
      for (let i = 0; i < document.getElementsByClassName("top__navigation__link").length; i++) {
        document.getElementById("top__navigation__link--" + i).classList.remove("active");
      }
    }
  }
}