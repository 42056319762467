import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router";
import { filter } from 'rxjs/operators'
import { PreviousIdService } from "../../../services/previousID.service";
@Component({
  selector: 'div[app-top-navigation]',
  templateUrl: './top-navigation.component.html',
  styleUrls: ['./top-navigation.component.scss'],
  providers: [PreviousIdService],
  host: {
    '(window:resize)': 'onWindowResize($event)'
  }
})
export class TopNavigationComponent implements OnInit, AfterViewInit, OnDestroy {

  params: any = {
    "utm_medium": "web",
    "utm_source": "VIDA_web",
    "utm_campaign": "web1",
    "utm_content": "contact",
    "alias": "view_contact_us"
  };
  popOver: any = {
    id: "popOverContact",
    title: 'Let’s keep in touch!',
    text: [
      "If you have questions, we’re here to help.",
      "Email us at <a href='mailto:support@vorvidacompass.com' target='blank' class='popOverLink' aria-label='mail to support@vorvidacompass.com - opens email client'>support@vorvidacompass.com</a> or call us at <a href='tel:1-833-8678432' class='popOverLink text-nowrap' aria-label='Call 1-833-8678432'>1-833-VORVIDA</a> for more information.",
      "<span class='popOverDate'>Monday through Friday:</span>  8 AM to 8 PM ET"
    ]
  }

  previousID: string = '';

  constructor(private router: Router, private id: PreviousIdService) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.id.set("previousId", [])
  }

  ngAfterViewInit() {
    this.router.events
      .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
      .subscribe(event => {
        if (
          event.id === 1 &&
          event.url === event.urlAfterRedirects
        ) {
          if ((this.router.url.includes("/hcp") || this.router.url.includes("/sign_up")) && document.body.offsetWidth > 992) {
            document.getElementById("top__navigation__link--" + this.id.get("previousId")).classList.add("active")
          }
        }
      })
  }

  stopRedirecting(link: string, params: any, linkId: string) {
    if (link != '/') {
      this.router.navigate(["/" + link], { queryParams: params })
      //Set local storage so we save the last link when refresh browser
      this.id.set("previousId", linkId)
    }
    this.setNewActive(linkId);
  }

  setNewActive(id: any) {
    for (let i = 0; i < document.getElementsByClassName("top__navigation__link").length; i++) {
      if ((document.getElementsByClassName("top__navigation__link")[i].getAttribute("id").indexOf("top__navigation__link--" + id) > -1)) {
        document.getElementById("top__navigation__link--" + i).classList.add("active")
      } else {
        document.getElementById("top__navigation__link--" + i).classList.remove("active")
      }
      if (id === 1) {
        document.getElementById("top__navigation__link--" + id).classList.add("active")
      }

      if (this.id.get("previousId").length > 0 || typeof this.id.get("previousId") === "number") {
        document.getElementById("top__navigation__link--" + this.id.get("previousId")).classList.add("active")
      }
    }
  }

  onWindowResize(event) {
    if (this.router.url.includes("/info-healthcare-providers")) {
      this.setNewActive(2);
    }
  }

}
