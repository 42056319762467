import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router'
import { Observable } from 'rxjs'
import { Injectable } from '@angular/core'
import { AlcoholService } from '../services/alcohol.service'

@Injectable()
export class AlcoholResolver implements Resolve<[]> {
  constructor(private alcoholData: AlcoholService) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<[]> | Promise<[]> | [] {
    return this.alcoholData.getAlcoholData()
  }
}
