import { Directive, ElementRef, Renderer2, HostListener, ContentChildren, Inject } from "@angular/core";
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: '[appHeaderBG]'
})
export class HeaderBGDirective {

  constructor(private elRef: ElementRef, private renderer: Renderer2, @Inject(DOCUMENT) private document: Document) {
  }

  @HostListener('window:resize', ['$event']) onResize(event) {
    if (document.body.offsetWidth > 992) {
      this.renderer.removeClass(this.elRef.nativeElement, 'header__bg')
      this.renderer.removeClass(document.getElementsByClassName("navbar-brand-logo-path")[0], 'navbar-brand-logo-path-active')
      this.renderer.removeClass(document.getElementsByClassName("navbar__toggler__bar")[0], 'navbar__toggler__bar--active')
      this.renderer.removeClass(document.getElementsByClassName("navbar__toggler__bar")[1], 'navbar__toggler__bar--active')
      this.renderer.removeClass(document.getElementsByClassName("navbar__toggler__bar")[2], 'navbar__toggler__bar--active')
      this.renderer.removeClass(document.getElementsByClassName("navbar-text")[0], 'navbar-text--active')
      this.renderer.removeClass(document.getElementsByClassName("navbar-brand")[0], 'menu__open')
    }

  }

  @HostListener('window:scroll', []) onScroll(event: any) {
    if ((document.body.offsetWidth < 992 && typeof document.getElementsByClassName("navbar__toggler__x")[0] === 'undefined')) {

      if ((window.scrollY + 45) >= document.querySelector("header").offsetHeight) {
        this.renderer.addClass(this.elRef.nativeElement, 'header__bg')
        this.document.getElementById('navbar-brand-logo-path')?.classList?.add('mob');
        this.renderer.addClass(document.getElementsByClassName("navbar-brand-logo-path")[0], 'navbar-brand-logo-path-active')
        this.renderer.addClass(document.getElementsByClassName("navbar__toggler__bar")[0], 'navbar__toggler__bar--active')
        this.renderer.addClass(document.getElementsByClassName("navbar__toggler__bar")[1], 'navbar__toggler__bar--active')
        this.renderer.addClass(document.getElementsByClassName("navbar__toggler__bar")[2], 'navbar__toggler__bar--active')
        this.renderer.addClass(document.getElementsByClassName("navbar-text")[0], 'navbar-text--active')

        this.renderer.addClass(document.getElementsByClassName("navbar-text")[0], 'btn-primary')
        this.renderer.removeClass(document.getElementsByClassName("navbar-text")[0], 'btn-default')
      } else {
        this.renderer.removeClass(this.elRef.nativeElement, 'header__bg');
        this.document.getElementById('navbar-brand-logo-path')?.classList?.remove('mob');
        this.renderer.removeClass(document.getElementsByClassName("navbar-brand-logo-path")[0], 'navbar-brand-logo-path-active')
        this.renderer.removeClass(document.getElementsByClassName("navbar__toggler__bar")[0], 'navbar__toggler__bar--active')
        this.renderer.removeClass(document.getElementsByClassName("navbar__toggler__bar")[1], 'navbar__toggler__bar--active')
        this.renderer.removeClass(document.getElementsByClassName("navbar__toggler__bar")[2], 'navbar__toggler__bar--active')
        this.renderer.removeClass(document.getElementsByClassName("navbar-text")[0], 'navbar-text--active')

        this.renderer.addClass(document.getElementsByClassName("navbar-text")[0], 'btn-default')
        this.renderer.removeClass(document.getElementsByClassName("navbar-text")[0], 'btn-primary')
      }
    }
  }
}