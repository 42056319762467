import { Injectable } from "@angular/core";

@Injectable()
export class PreviousIdService {
  set(key: string, data: any): void {
    localStorage.setItem(key, JSON.stringify(data));
  }

  get(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }
}