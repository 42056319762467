import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';

//Global components
import { AppComponent } from './app.component';

//Global modules
import { HeaderModule } from './shared/components/header/header.module';

//Directive 
import { HeaderBGDirective } from "./shared/directives/header-bg.directive";
import { PlayVideoDirective } from "./shared/directives/play-video.directive";

//Services
import { Aboutservice } from "./shared/services/about.service";
import { AlcoholService } from "./shared/services/alcohol.service";
import { GettingStartedService } from "./shared/services/getting-started.service";
import { HCPservice } from "./shared/services/hcp.service";
import { SupportServicesService } from "./shared/services/support-services";
import { ErrorService } from "./shared/services/error.service";
import { RegisterForUpdatesService } from "./shared/services/register-for-updates.service";

//Resolver
import { AboutResolver } from "./shared/resolvers/about-resolver.service";
import { AlcoholResolver } from "./shared/resolvers/alcohol-resolver.service";
import { GettingStartedResolver } from "./shared/resolvers/getting-started-resolver.service";
import { HCPResolver } from "./shared/resolvers/hcp-resolver.service";
import { SupportServicesResolver } from "./shared/resolvers/support-services-resolver.service";
import { ErrorResolver } from "./shared/resolvers/error-resolver.service";
import { RegisterForUpdatesResolver } from "./shared/resolvers/register-for-updates-resolver.service";

import { OfferComponent } from './shared/components/header/offer/offer.component';
// import { WhatIsComponent } from './shared/components/header/what-is/what-is.component'

import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';

@NgModule({
  declarations: [
    AppComponent,
    HeaderBGDirective,
    PlayVideoDirective,
    OfferComponent,
    // WhatIsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HeaderModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientJsonpModule
  ],
  providers: [Aboutservice,
    AboutResolver,
    AlcoholService,
    AlcoholResolver,
    GettingStartedService,
    GettingStartedResolver,
    HCPservice,
    HCPResolver,
    SupportServicesService,
    SupportServicesResolver,
    ErrorService,
    ErrorResolver,
    RegisterForUpdatesService,
    RegisterForUpdatesResolver
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
