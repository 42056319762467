import { Directive, HostListener, Renderer2 } from "@angular/core";

@Directive({
  selector: "[appCheckHeaderOpen]"
})
export class CheckHeaderOpenDirective {

  scrollY: number = 0;

  constructor(private renderer: Renderer2) { }

  @HostListener('click') onClick() {

    if (typeof document.getElementsByClassName("menu__is__open")[0] === "undefined") {
      this.renderer.removeClass(document.getElementsByTagName('header')[0], 'header__bg')
      if (document.body.offsetWidth < 992) {
        this.renderer.setStyle(document.body, "overflow", "hidden");
        document.getElementsByTagName("html")[0].style.overflow = 'hidden';
      }
    } else if (this.scrollY >= document.querySelector("header").offsetHeight) {
      this.renderer.addClass(document.getElementsByTagName('header')[0], 'header__bg')
      //Close if it was open and scrolly is more than header offsetheight
      if (document.body.offsetWidth < 992) {
        this.renderer.removeStyle(document.body, "overflow");
        document.getElementsByTagName("html")[0].removeAttribute("style");
      }
    } else {
      //Close if it was open and scrolly is less than header offsetheight
      if (document.body.offsetWidth < 992) {
        this.renderer.removeStyle(document.body, "overflow");
        document.getElementsByTagName("html")[0].removeAttribute("style");
      }
    }
  }

  @HostListener('document:scroll', ['$event']) onScroll(e): void {
    this.scrollY = window.scrollY;
  }
}  