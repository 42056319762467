<!-- <div class="bg-light-blue top__banner" role="banner">
  <p *ngIf="!internalPage" class="m-0 text-white text-center top__banner__copy"><b>40% off</b> vorvida® with code <b>MBG2021</b> at checkout</p>
  <p *ngIf="internalPage" class="m-0 text-white text-center top__banner__copy"><b>40% off</b> vorvida® with code <b>MBG2021</b> at checkout</p>
</div> -->
<header app-header appHeaderBG id="header"></header>
<div class="accordions" role="region">
  <!-- <div *ngIf="internalPage" app-offer></div> -->
  <!-- <div *ngIf="internalPage" app-what-is></div> -->
</div>
<main id="main" appPlayVideo [activateDirective]="activate">
  <router-outlet (activate)="changeValue()"></router-outlet>
</main>