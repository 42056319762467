<ul class="top__navigation__items">
  <li class="top__navigation__item">
    <a (click)="stopRedirecting('sign_up', null, '0')" class="top__navigation__link" id="top__navigation__link--0" role="button">Register for Updates</a>
  </li>
  <li class="top__navigation__item">
    <a aria-haspopup="true" aria-expanded="false" (click)="stopRedirecting('/', params, '1')" class="top__navigation__link" id="top__navigation__link--1" appOpenPopOver role="button">Contact Us</a>
    <div app-popover [popOverContent]="popOver" id="popOverContact"></div>
  </li>
  <li class="top__navigation__item">
    <a (click)="stopRedirecting('hcp', null, '2')" class="top__navigation__link" id="top__navigation__link--2" role="button">Info for Healthcare Providers</a>
  </li>
</ul>
<div class="top__navigation__sign-up">
  <p class="top__navigation__sign-up__text">Already a vorvida user?</p>
  <a href="https://vorvida.broca.io" rel="noopener" target="_blank" class="top__navigation__sign-up__link" aria-label="Sign In - link opens in a new tab">Sign
    In</a>
</div>