<div class="tab py-1">
  <div
    class="
      tab__header
      text-center
      py-2
      px-4
      d-flex
      justify-content-around justify-content-lg-center
    "
  >
    Try vorvida® risk-free for only $59.90/month
    <a *ngIf="!isOpen" (click)="toggleTab($event)" class="ml-lg-2">Details</a>
    <a *ngIf="isOpen" (click)="toggleTab($event)" class="ml-lg-2">Close</a>
  </div>

  <div
    class="container-xxl tab__container mx-auto"
    [ngClass]="{ open: isOpen }"
  >
    <div class="row fixedpad">
      <div class="col-8 offset-lg-1 col-lg-7">
        <div class="offer__container">
          <p class="mb-1 mt-3">
            For just <del>$599</del>&nbsp;<b
              >$359.40 or $59.90/mo for 6 months</b
            >
            with code <strong>MBG2021</strong>, you'll have access to vorvida®,
            including:
          </p>

          <div class="d-flex mt-2">
            <ul class="w-50 pr-lg-3">
              <li class="d-flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="34.21"
                  height="34.21"
                  viewBox="0 0 34.21 34.21"
                >
                  <defs>
                    <clipPath id="clip-path">
                      <path
                        class="cls-1"
                        d="M27.21,11.08a1,1,0,0,1,0,1.37L16.16,24a2.37,2.37,0,0,1-3.35.08L12.73,24,9.54,20.67a1,1,0,0,1,0-1.38.9.9,0,0,1,1.27,0l0,0,3.6,3.77,11.45-12a.9.9,0,0,1,1.28,0Z"
                      />
                    </clipPath>
                  </defs>
                  <g>
                    <circle fill="#919d6c" cx="17.11" cy="17.11" r="17.11" />
                    <g clip-path="url(#clip-path)">
                      <rect
                        fill="#fff"
                        x="3.34"
                        y="4.87"
                        width="30.07"
                        height="25.8"
                      />
                    </g>
                  </g>
                </svg>
                <p>
                  6 months of tailored
                  <span class="text-nowrap">psychology-based</span> techniques
                </p>
              </li>
              <li class="d-flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="34.21"
                  height="34.21"
                  viewBox="0 0 34.21 34.21"
                >
                  <defs>
                    <clipPath id="clip-path">
                      <path
                        class="cls-1"
                        d="M27.21,11.08a1,1,0,0,1,0,1.37L16.16,24a2.37,2.37,0,0,1-3.35.08L12.73,24,9.54,20.67a1,1,0,0,1,0-1.38.9.9,0,0,1,1.27,0l0,0,3.6,3.77,11.45-12a.9.9,0,0,1,1.28,0Z"
                      />
                    </clipPath>
                  </defs>
                  <g>
                    <circle fill="#919d6c" cx="17.11" cy="17.11" r="17.11" />
                    <g clip-path="url(#clip-path)">
                      <rect
                        fill="#fff"
                        x="3.34"
                        y="4.87"
                        width="30.07"
                        height="25.8"
                      />
                    </g>
                  </g>
                </svg>
                <p>
                  Weekly alcohol and mood checks to&nbsp;track your progress
                </p>
              </li>
            </ul>
            <ul class="w-50 pl-3">
              <li class="d-flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="34.21"
                  height="34.21"
                  viewBox="0 0 34.21 34.21"
                >
                  <defs>
                    <clipPath id="clip-path">
                      <path
                        class="cls-1"
                        d="M27.21,11.08a1,1,0,0,1,0,1.37L16.16,24a2.37,2.37,0,0,1-3.35.08L12.73,24,9.54,20.67a1,1,0,0,1,0-1.38.9.9,0,0,1,1.27,0l0,0,3.6,3.77,11.45-12a.9.9,0,0,1,1.28,0Z"
                      />
                    </clipPath>
                  </defs>
                  <g>
                    <circle fill="#919d6c" cx="17.11" cy="17.11" r="17.11" />
                    <g clip-path="url(#clip-path)">
                      <rect
                        fill="#fff"
                        x="3.34"
                        y="4.87"
                        width="30.07"
                        height="25.8"
                      />
                    </g>
                  </g>
                </svg>
                <p>24/7 program access across connected devices</p>
              </li>
              <li class="d-flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="34.21"
                  height="34.21"
                  viewBox="0 0 34.21 34.21"
                >
                  <defs>
                    <clipPath id="clip-path">
                      <path
                        class="cls-1"
                        d="M27.21,11.08a1,1,0,0,1,0,1.37L16.16,24a2.37,2.37,0,0,1-3.35.08L12.73,24,9.54,20.67a1,1,0,0,1,0-1.38.9.9,0,0,1,1.27,0l0,0,3.6,3.77,11.45-12a.9.9,0,0,1,1.28,0Z"
                      />
                    </clipPath>
                  </defs>
                  <g>
                    <circle fill="#919d6c" cx="17.11" cy="17.11" r="17.11" />
                    <g clip-path="url(#clip-path)">
                      <rect
                        fill="#fff"
                        x="3.34"
                        y="4.87"
                        width="30.07"
                        height="25.8"
                      />
                    </g>
                  </g>
                </svg>
                <p>Goal-setting tools</p>
              </li>
              <li class="d-flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="34.21"
                  height="34.21"
                  viewBox="0 0 34.21 34.21"
                >
                  <defs>
                    <clipPath id="clip-path">
                      <path
                        class="cls-1"
                        d="M27.21,11.08a1,1,0,0,1,0,1.37L16.16,24a2.37,2.37,0,0,1-3.35.08L12.73,24,9.54,20.67a1,1,0,0,1,0-1.38.9.9,0,0,1,1.27,0l0,0,3.6,3.77,11.45-12a.9.9,0,0,1,1.28,0Z"
                      />
                    </clipPath>
                  </defs>
                  <g>
                    <circle fill="#919d6c" cx="17.11" cy="17.11" r="17.11" />
                    <g clip-path="url(#clip-path)">
                      <rect
                        fill="#fff"
                        x="3.34"
                        y="4.87"
                        width="30.07"
                        height="25.8"
                      />
                    </g>
                  </g>
                </svg>
                <p>Daily messages for inspiration and encouragement</p>
              </li>
            </ul>
          </div>

          <p class="mb-5">
            <button
              (click)="goTo('http://orexo.mybigcommerce.com/')"
              class="btn btn-primary align-self-end"
            >
              GET&nbsp;VORVIDA
            </button>
          </p>

          <p>
            Use vorvida® for at least 60 days, and if you’re not completely
            satisfied with your experience, contact us at
            <a href="mailto:support@vorvidacompass.com"
              ><u>support@vorvidacompass.com</u></a
            >
            and we’ll give you a full refund of your purchase.* See if vorvida®
            can help you take control of your drinking today!
          </p>
          <small class="d-flex mb-4 pb-2"
            ><span>*</span
            ><span
              ><em
                >In order for your refund request to be considered valid, it
                must&nbsp;be requested within 30&nbsp;days of
                <span class="text-nowrap">refund eligibility.</span></em
              ></span
            ></small
          >
        </div>
      </div>
    </div>
  </div>
</div>
