import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router'
import { Observable } from 'rxjs'
import { Injectable } from '@angular/core'
import { GettingStartedService } from '../services/getting-started.service'

@Injectable()
export class GettingStartedResolver implements Resolve<[]> {
  constructor(private gettingStartedData: GettingStartedService) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<[]> | Promise<[]> | [] {
    return this.gettingStartedData.getGettingStartedData()
  }
}
