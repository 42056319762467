import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router'
import { Observable } from 'rxjs'
import { Injectable } from '@angular/core'
import { Aboutservice } from '../services/about.service'

@Injectable()
export class AboutResolver implements Resolve<[]> {
  constructor(private aboutData: Aboutservice) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<[]> | Promise<[]> | [] {
    return this.aboutData.getAboutData()
  }
}
