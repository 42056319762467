import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router'
import { Observable } from 'rxjs'
import { Injectable } from '@angular/core'
import { HCPservice } from '../services/hcp.service'

@Injectable()
export class HCPResolver implements Resolve<[]> {
  constructor(private hcpData: HCPservice) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<[]> | Promise<[]> | [] {
    return this.hcpData.getHCPData()
  }
}
