import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'div[app-popover]',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
  host: {
    class: "popoverGlobal"
  },
})
export class PopoverComponent implements OnInit {

  @Input() popOverContent: any;
  @Input() direction: string;

  constructor() { }

  ngOnInit(): void {
  }

}
