import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions, PreloadAllModules } from '@angular/router';

//Resolvers
import { AboutResolver } from "./shared/resolvers/about-resolver.service";
import { AlcoholResolver } from "./shared/resolvers/alcohol-resolver.service";
import { GettingStartedResolver } from "./shared/resolvers/getting-started-resolver.service";
import { HCPResolver } from "./shared/resolvers/hcp-resolver.service";
import { SupportServicesResolver } from "./shared/resolvers/support-services-resolver.service";
import { ErrorResolver } from "./shared/resolvers/error-resolver.service";
import { RegisterForUpdatesResolver } from "./shared/resolvers/register-for-updates-resolver.service";

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'top',
  preloadingStrategy: PreloadAllModules,
  anchorScrolling: 'enabled',
  // scrollOffset: [0, 100],
}

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./homepage/homepage.module').then((m) => m.HomepageModule),
  },
  {
    path: 'about',
    loadChildren: () =>
      import('./pages/about/about.module').then((m) => m.AboutModule),
    resolve: { aboutData: AboutResolver },
  },
  {
    path: 'alcohol_health',
    loadChildren: () =>
      import('./pages/alcohol/alcohol.module').then((m) => m.AlcoholModule),
    resolve: { alcoholData: AlcoholResolver }
  },
  {
    path: 'get_started',
    loadChildren: () =>
      import('./pages/getting-started/getting-started.module').then((m) => m.GettingStartedModule),
    resolve: { gettingStartedData: GettingStartedResolver }
  },
  {
    path: 'support',
    loadChildren: () =>
      import('./pages/support-services/support-services.module').then((m) => m.SupportServicesModule),
    resolve: { supportServicesData: SupportServicesResolver }
  },
  {
    path: 'hcp',
    loadChildren: () =>
      import('./pages/hcp/hcp.module').then((m) => m.HcpModule),
    resolve: { hcpData: HCPResolver }
  },
  {
    path: 'sign_up',
    loadChildren: () =>
      import('./pages/register-for-updates/register-for-updates.module').then((m) => m.RegisterForUpdatesModule),
    resolve: { rfuData: RegisterForUpdatesResolver }
  },
  {
    path: 'page-not-found',
    loadChildren: () =>
      import('./pages/error404/error404.module').then((m) => m.Error404Module),
    resolve: { errorData: ErrorResolver }
  },
  {
    path: 'internal-error',
    loadChildren: () =>
      import('./pages/error500/error500.module').then((m) => m.Error500Module),
    resolve: { errorData: ErrorResolver }
  },
  {
    path: '**',
    redirectTo: 'page-not-found',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
