import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router'
import { Observable } from 'rxjs'
import { Injectable } from '@angular/core'
import { ErrorService } from '../services/error.service'

@Injectable()
export class ErrorResolver implements Resolve<[]> {
  constructor(private errorData: ErrorService) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<[]> | Promise<[]> | [] {
    return this.errorData.getErrorData()
  }
}
