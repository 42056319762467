import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router'
import { Observable } from 'rxjs'
import { Injectable } from '@angular/core'
import { SupportServicesService } from '../services/support-services'

@Injectable()
export class SupportServicesResolver implements Resolve<[]> {
  constructor(private supportServicesData: SupportServicesService) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<[]> | Promise<[]> | [] {
    return this.supportServicesData.getSupportServicesData()
  }
}
