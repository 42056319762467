import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router'
import { Observable } from 'rxjs'
import { Injectable } from '@angular/core'
import { RegisterForUpdatesService } from '../services/register-for-updates.service'

@Injectable()
export class RegisterForUpdatesResolver implements Resolve<[]> {
  constructor(private rfuData: RegisterForUpdatesService) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<[]> | Promise<[]> | [] {
    return this.rfuData.registerForUpdatesData()
  }
}
