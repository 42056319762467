import { Directive, HostListener, Input, OnChanges } from "@angular/core";
import { Router } from "@angular/router"
@Directive({
  selector: "[appPlayVideo]"
})
export class PlayVideoDirective implements OnChanges {
  video: any;
  rect: any;
  @Input() activateDirective: boolean = false;
  constructor(private router: Router) { }

  ngOnChanges() {
    setTimeout(() => {
      if (typeof document.getElementsByTagName("video")[0] != "undefined") {
        this.video = <HTMLVideoElement>document.getElementsByTagName("video")[0];
      } else {
        this.video = 'undefined';
      }
    }, 200)

  }

  isElementInViewportTop(el) {
    this.rect = document.getElementById(el).getBoundingClientRect();
    return (
      this.rect.top <= (document.getElementById(el).offsetHeight) * -1
    );
  }

  isElementInViewportBottom(el) {
    this.rect = document.getElementById(el).getBoundingClientRect();
    // console.log(this.rect.bottom)
    // console.log(document.getElementById(el).offsetHeight * -1)
    // console.log(this.rect.bottom < document.getElementById(el).offsetHeight)
    return (
      this.rect.bottom <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  @HostListener('document:scroll', ['$event']) onScroll(e): void {
    if (this.video != 'undefined') {
      if (this.isElementInViewportTop(this.video.id)) {
        this.video.pause();
      }
      
      if (!this.isElementInViewportBottom(this.video.id) && this.router.url.split('?')[0] === '/hcp') {
        this.video.pause();
      }
      if (this.router.url.split('?')[0] === '/') {
        if (window.scrollY === 0 && !(navigator.userAgent.toLowerCase().indexOf('safari/') > -1)) {
          this.video.pause();
        }
      }
    }
  }

}