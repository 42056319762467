import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router"
import { NavigationComponent } from "./navigation.component"

//NGB bootstrap
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap'

//Directive
import { CheckHeaderOpenDirective } from "../../../directives/check-header-open.directive";

@NgModule({
  declarations: [NavigationComponent, CheckHeaderOpenDirective],
  imports: [
    CommonModule,
    RouterModule,
    NgbCollapseModule
  ],
  exports: [NavigationComponent]
})
export class NavigationModule { }
