import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'div[app-offer]',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss']
})
export class OfferComponent implements OnInit {

  isOpen = false;

  constructor() { }

  toggleTab(event) {
    this.isOpen = !this.isOpen;
    event.stopPropagation();
  }

  ngOnInit(): void {
  }

  goTo(url: string) {
    window.open(url, '_blank')
  }

}
