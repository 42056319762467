import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeaderComponent } from "./header.component";
import { TopNavigationModule } from './top-navigation/top-navigation.module';
import { NavigationModule } from './navigation/navigation.module'



@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    TopNavigationModule,
    NavigationModule
  ],
  exports: [HeaderComponent]
})
export class HeaderModule { }
