import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'div[app-navigation]',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  public isMenuCollapsed = true;

  list: any[] = [
    {
      linkName: "Alcohol & Health",
      linkUrl: "alcohol_health"
    },
    {
      linkName: "About vorvida",
      linkUrl: "about"
    },
    {
      linkName: "Getting Started",
      linkUrl: "get_started"
    },
    {
      linkName: "Support Services",
      linkUrl: "support"
    },
    {
      linkName: 'Get Vorvida',
      linkUrl: 'http://orexo.mybigcommerce.com/'
    }
  ]

  mobileSubList: any[] = [
    {
      linkName: "Register for Updates",
      linkUrl: "sign_up"
    },
    {
      linkName: "Info for Healthcare Providers",
      linkUrl: "hcp"
    },
    {
      linkName: "Sign In",
      text: "Already a vorvida user?",
      linkUrl: "https://us.vorvida.com/sign_up"
    }
  ]

  constructor() { }

  ngOnInit(): void {

  }

  goTo() {
    window.open("http://orexo.mybigcommerce.com/", '_blank');
  }


}
