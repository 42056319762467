<nav class="navbar navbar-expand-lg mb-3 mb-lg-0" [ngClass]="{menu__is__open: !isMenuCollapsed}">
  <a class="navbar-brand" [routerLink]="'/'" [ngClass]="{menu__open: !isMenuCollapsed}">
    <svg role="img" aria-label="vorvida logo" id="navbar-brand-l" class="navbar-brand-logo" data-name="navbar-brand-l" xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 460.8 86">
      <path class="navbar-brand-logo-path" id="navbar-brand-logo-path" fill="#919d6d"
        d="M294,70a11.4,11.4,0,0,1,5.6,3.2,7,7,0,0,1,1.6,6,8.14,8.14,0,0,1-8,6.8H292a8.89,8.89,0,0,1-5.2-3.2,6.69,6.69,0,0,1-1.6-6,7.45,7.45,0,0,1,7.6-6.8Zm3.6-70-2.8,17.2a102,102,0,0,0-.8,15.6,94.31,94.31,0,0,1-.8,15.2c-.4,3.2-2,12.4-2,12.4v.4l9.2,1.6V62s1.6-9.2,2-12.4a101.58,101.58,0,0,1,4-14.8,94.22,94.22,0,0,0,4-15.2l2.8-17.2L297.6,0Zm-174,44A21.85,21.85,0,0,1,102,66C90,66,80.8,56,80.8,44a21.4,21.4,0,0,1,42.8,0Zm-55.2.4c0,18.4,15.2,33.6,34.4,34a34.06,34.06,0,0,0,34-34.4c0-18.4-15.6-33.6-34-33.6-19.2,0-34.4,14.8-34.4,34Zm106.8,2h-6.8V22.8h8.4c3.6,0,6.4,0,9.2,2.4a11.82,11.82,0,0,1,0,18.4c-3.2,2.8-6.8,2.8-10.8,2.8ZM156.4,22l-.8,54.4H168V47.2l20.4,29.2h15.2V66h-7.2l-10-12.8c9.6-.8,16-10.4,16-19.6,0-6.8-2.4-12.8-7.6-17.2-4.8-4-10.4-4.8-16.8-4.8H149.2V22ZM343.6,65.6V23.2h10c6,0,10.8.8,15.2,5.2,4,4.4,5.6,10.4,5.6,16.4,0,5.2-1.6,11.2-5.2,15.2-4.4,4.8-10,5.6-16,5.6ZM331.2,12V66.4H324V76.8h30.8c9.6,0,17.2-2.4,23.6-9.6a34.21,34.21,0,0,0,8.4-22.8c0-8.8-2.8-17.6-9.2-24C370.8,14,364,12,354.8,12Zm-52.8,0H256V22h6L248.4,59.6,235.6,22h6V12h-26V22h6.8l18.8,54.8h13.6ZM62.8,12H40.4V22h6.4l-14,37.6L20,22h6.4V12H0V22H6.8L25.6,76.8H39.2Zm354,36.4h16.4l-8-22.4h0l-8.4,22.4Zm36.8,18h7.2V76.8H444L436.8,58H414l-7.2,18.8H393.2l23.2-54.4h-7.2V12h21.6l22.8,54.4Z" />
    </svg>
    <span class="sr-only">vorvida</span>
  </a>

  <!-- Get Vorvida -->
  <a class="navbar-text mr-3 btn btn-default d-lg-none" rel="noopener" href="http://orexo.mybigcommerce.com/" target="_blank"
    [ngClass]="{menu__open: !isMenuCollapsed}">Get vorvida</a>

  <!-- Menu toggler -->
  <div class="navbar__toggler d-flex flex-column d-lg-none" appCheckHeaderOpen
    (click)="isMenuCollapsed = !isMenuCollapsed" [ngClass]="{ navbar__toggler__x: !isMenuCollapsed }">
    <span class="navbar__toggler__bar navbar__toggler__bar-top"></span>
    <span class="navbar__toggler__bar navbar__toggler__bar-middle"></span>
    <span class="navbar__toggler__bar navbar__toggler__bar-bottom"></span>
  </div>

  <!-- Menu Desktop -->
  <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse">
    <a class="navbar-brand d-lg-none" [routerLink]="'/'" (click)="isMenuCollapsed = true" appCheckHeaderOpen>
      <img
        srcset="/assets/images/global/header/Vorvida_Open.png, /assets/images/global/header/Vorvida_Open@2x.png 2x, /assets/images/global/header/Vorvida_Open@3x.png 3x"
        src="/assets/images/global/header/Vorvida_Open.png" alt="Vorvida Logo" class="img-fluid" />
    </a>
    <ul class="navbar-nav">
      <li *ngFor="let item of list; index as i" class="nav-item" [routerLinkActiveOptions]="{ exact: true }">
        <a *ngIf="i != 4" class="nav-link" [routerLinkActive]="['active']" [routerLink]="item.linkUrl"
          (click)="isMenuCollapsed = true" appCheckHeaderOpen>{{ item.linkName }}</a>
        <a *ngIf="i === 4" rel="noopener" class="nav-link" href="http://orexo.mybigcommerce.com/" target="_blank"
          (click)="isMenuCollapsed = true" appCheckHeaderOpen>{{ item.linkName }}</a>
      </li>
    </ul>
    <ul class="navbar-collapse-sublist">
      <li *ngFor="let item of mobileSubList; index as i" class="navbar-collapse-sublist-item">
        {{ item.text }}
        <a *ngIf="i != 2" class="navbar-collapse-sublist-link" [routerLinkActive]="['active']"
          [routerLinkActiveOptions]="{ exact: true }" [routerLink]="item.linkUrl" (click)="isMenuCollapsed = true"
          appCheckHeaderOpen>{{ item.linkName }}</a>
        <a *ngIf="i === 2" rel="noopener" class="navbar-collapse-sublist-link" href="https://vorvida.broca.io"
          target="_blank" (click)="isMenuCollapsed = true" appCheckHeaderOpen>{{ item.linkName }}</a>
      </li>
    </ul>
    <div class="navbar-collapse__container-text">
      <h3>Let’s keep in touch</h3>
      <p>If you have questions, we're here to help.</p>
      <p style="width:101%">Email us at <a href="mailto:support@vorvidacompass.com" aria-label='mail to support@vorvidacompass.com - opens email client'>support@vorvidacompass.com</a> or call
        us at <a href="tel:1-833-8678432" class="text-nowrap" aria-label='Call 1-833-8678432'>1-833-VORVIDA</a> for more information.</p>
      <p><strong>Monday – Friday</strong> 8:00 AM to 8:00 PM ET</p>
    </div>
    <button class="d-none d-lg-block btn btn-primary" (click)="goTo()">GET VORVIDA</button>
  </div>
  <div class="navbar-overlay-mob"></div>
</nav>