import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OpenPopoverDirective } from "../open-popover.directive";

@NgModule({
  declarations: [OpenPopoverDirective],
  imports: [
    CommonModule
  ],
  exports: [OpenPopoverDirective]
})
export class GlobalDirectiveModule { }
