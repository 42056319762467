import { Component, Inject, PLATFORM_ID, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { CanonicalService } from './shared/services/canonical.service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'vorvida';
  activate: boolean = false;
  internalPage: boolean = false;
  tab: string = 'none';
  constructor(
    @Inject(PLATFORM_ID) private platformid: any,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private activatedRoute: ActivatedRoute,
    private canonicalService: CanonicalService
  ) {}

  ngAfterViewInit() {}

  changeValue() {
    this.internalPage = this.router.url.split('?')[0] !== '/';
    this.activate === false ? (this.activate = true) : (this.activate = false);
    (this.document.querySelector('#skipnav') as HTMLElement).setAttribute('href', this.router.url.split('?')[0].split('#')[0] + '#main');
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformid)) {
      if (this.canUseWebP()) {
        this.renderer.addClass(document.body, 'webp');
        this.renderer.removeClass(document.body, 'nowebp');
      } else {
        this.renderer.addClass(document.body, 'nowebp');
        this.renderer.removeClass(document.body, 'webp');
      }
    }

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        })
      )
      .pipe(filter((route) => route.outlet === 'primary'))
      .pipe(mergeMap((route) => route.data))
      .subscribe((event) => {
        event['title'] && this.titleService.setTitle(event['title']);

        //META TAGS
        event['description'] &&
          this.meta.updateTag({
            name: 'description',
            content: event['description'],
          });

        //OG TAGS
        event['ogtitle'] &&
          this.meta.updateTag({ name: 'og:title', content: event['ogtitle'] });
        event['ogdescription'] &&
          this.meta.updateTag({
            name: 'og:description',
            content: event['ogdescription'],
          });
        event['twtitle'] &&
          this.meta.updateTag({
            name: 'twitter:title',
            content: event['twtitle'] || event['og:title'],
          });
        event['twdescription'] &&
          this.meta.updateTag({
            name: 'twitter:description',
            content: event['twdescription'],
          });

        // Canonical
        this.canonicalService.createLinkForCanonicalURL();
      });
  }

  private canUseWebP(): boolean {
    if (isPlatformBrowser(this.platformid)) {
      let elem = document.createElement('canvas');
      if (!!(elem.getContext && elem.getContext('2d'))) {
        // was able or not to get WebP representation
        return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
      }

      // very old browser like IE 8, canvas not supported
    }
    return false;
  }
}
