import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router"
import { TopNavigationComponent } from "./top-navigation.component"

//Popover
import { PopoverModule } from "../../../components/popover/popover.module";

//Directive
import { GlobalDirectiveModule } from "../../../directives/global-directive/global-directive.module";

@NgModule({
  declarations: [TopNavigationComponent],
  imports: [
    CommonModule,
    PopoverModule,
    RouterModule,
    GlobalDirectiveModule
  ],
  exports: [TopNavigationComponent]
})
export class TopNavigationModule { }
